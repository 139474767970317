@import '../../../styles/var';

.wrap {
    position: relative;
    display: grid;
    grid-template-columns: 368px auto;
    justify-content: space-between;
    gap: 80px;
    padding: 50px 50px 0;

    @media (max-width: $md3+px) {
        display: flex;
        flex-direction: column;
        gap: 60px;
        padding: 30px 50px 0;
    }

    @media(max-width: $md5+px) {
        gap: 30px;
        padding: 20px 20px 0;
    }

    @media(max-width: $md6+px) {
        padding: 0;
    }

    &::before {
        content: '';
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 540px;
        border-radius: 20px;
        background: #EFECE7;
        top: 0;
        left: 0;

        @media(max-width: $md6+px) {
            display: none;
        }
    }

    .content {
        position: relative;
        z-index: 5;

        .title {
            color: #2F5D52;
        }

        .text {
            margin-top: 30px;
        }

        .btn {
            @media(max-width: $md3+px) {
                display: none;
            }
        }
    }

    .accountsWrap {
        position: relative;
        z-index: 5;
        display: flex;

        @media (max-width: $md3+px) {
            justify-content: center;
        }

        @media (max-width: $md5+px) {
            flex-direction: column;
            justify-content: unset;
            gap: 20px;
        }

        .buttons {
            width: 107px;
            display: flex;
            flex-direction: column;
            gap: 8px;

            @media (max-width: $md5+px) {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                width: 100%;
            }

            .button {
                width: 100%;
                height: 55px;
                border-radius: 10px 0 0 10px;
                color: #fff;
                font-family: $sans;
                font-size: 14px;
                font-weight: 600;
                line-height: normal;
                background: #551A11;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                cursor: pointer;
                transition: all .3s;

                @media (max-width: $md5+px) {
                    border-radius: 10px;
                }

                @media (max-width: $md6+px) {
                    height: 45px;
                }

                &.active {
                    background: $red;
                }
            }
        }

        .accounts {
            width: 520px;
            border-radius: 0 20px 20px 20px;
            background: #fff;
            padding: 20px 30px 30px;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);

            @media (max-width: $md5+px) {
                border-radius: 20px;
                width: 100%;
            }

            @media (max-width: 600px) {
                padding: 10px;
            }

            @media (max-width: $md6+px) {
                width: calc(100% + 36px);
                transform: translateX(-18px);
                border-radius: 0;
            }

            .row {
                border-bottom: 1px solid #AAA;
                display: grid;
                grid-template-columns: 53% 47%;
                height: 42px;
                align-items: center;

                font-family: $sans;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;

                .col {
                    padding: 0 10px;
                }

                img {
                    width: 20px;
                }
            }
        }
    }
}