@import '../../../styles/var';

.wrap {
    text-align: center;
    margin-top: 175px;

    @media(max-width: $md1+px) {
        margin-top: 140px;
    }

    @media(max-width: $md4+px) {
        margin-top: 120px;
    }

    @media(max-width: $md6+px) {
        margin-top: 100px;
    }

    .title {
        color: #000;
        font-family: $inter;
        font-size: 145px;
        font-weight: 700;
        line-height: 150%;

        @media(max-width: $md1+px) {
            font-size: 130px;
        }

        @media(max-width: $md4+px) {
            font-size: 110px;
        }

        @media(max-width: 600px) {
            font-size: 70px;
        }

        span {
            color: $red;
        }
    }

    .subtitle {
        margin-top: -30px;

        @media(max-width: 600px) {
            margin-top: -10px;
        }
    }

    .btn {}

    .cards {
        margin-top: 100px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(1, 1fr);
        gap: 80px;

        @media(max-width: $md1+px) {
            margin-top: 70px;
        }

        @media(max-width: $md3+px) {
            gap: 30px;
        }

        @media(max-width: $md4+px) {
            gap: 15px;
        }

        @media(max-width: $md5+px) {
            display: flex;
            flex-direction: column;
            margin-top: 50px;
        }

        @media(max-width: $md6+px) {
            gap: 10px;
        }

        .card {
            border-radius: 20px;
            background: $red;
            padding: 10px 10px 20px;
            color: #fff;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            @media(max-width: $md6+px) {
                padding: 10px 10px 15px;
            }

            .num {
                color: #FFF;
                text-align: center;
                font-family: $sans;
                font-size: 50px;
                font-weight: 700;
                line-height: 140%;

                @media(max-width: $md4+px) {
                    font-size: 40px;
                }

                @media(max-width: $md6+px) {
                    font-size: 30px;
                }
            }
        }
    }
}