@import '../../../styles/var';

.wrap {
    display: flex;
    justify-content: space-between;
    gap: 50px;

    @media(max-width: $md4+px) {
        flex-direction: column;
        align-items: center;
        gap: 40px;
    }


    .content {
        width: 100%;

        .title {
            max-width: 530px;
        }

        .text {
            max-width: 525px;
            margin-top: 30px;

            @media(max-width: $md4+px) {
                max-width: 100%;
            }
        }
    }

    .image {
        max-width: 490px;
        width: 100%;

        @media(max-width: $md4+px) {
            max-width: 400px;
        }

        @media(max-width: $md6+px) {
            max-width: 280px;
        }

        img {
            width: 100%;
        }
    }
}