@import '../../../styles/var';

.wrap {

    .title {}

    .text {
        margin: 15px 0 50px;

        @media(max-width: $md6+px) {
            margin-bottom: 40px;
        }
    }

    .tabs {
        display: grid;
        grid-template-columns: 314px 1fr;
        gap: 70px;

        @media(max-width: $md3+px) {
            gap: 50px;
        }

        @media(max-width: $md4+px) {
            display: flex;
            flex-direction: column;
        }

        @media(max-width: $md6+px) {
            gap: 40px;
        }

        .buttons {
            display: flex;
            flex-direction: column;
            gap: 15px;

            @media(max-width: $md4+px) {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
            }

            @media(max-width: 600px) {
                grid-template-columns: repeat(1, 1fr);
                gap: 10px;
            }

            .button {
                border-radius: 25px;
                background: #E4E4E4;
                text-align: center;
                padding: 8px;
                cursor: pointer;
                transition: all .2s;
                display: flex;
                align-items: center;
                justify-content: center;

                &.active {
                    background: #B73D2B;
                    color: #fff;
                }
            }
        }

        .content {

            .questions {
                margin-top: 30px;
                display: flex;
                flex-direction: column;
                gap: 30px;

                .question {

                    .subtitle {
                        color: #B73D2B;
                        font-weight: 700;
                        margin-bottom: 15px;
                    }
                }
            }
        }
    }
}