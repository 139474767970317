@import '../../../styles/var';

.regulation {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 50px;

    @media(max-width: 850px) {
        flex-direction: column;
        gap: 20px;
    }

    .content {
        max-width: 525px;

        @media(max-width: 850px) {
            max-width: 100%;
        }

        .text {
            margin-top: 30px;
        }
    }

    .image {
        width: 374px;
        margin-right: 50px;

        @media(max-width: $md3+px) {
            margin-right: 0;
        }

        @media(max-width: $md4+px) {
            width: 320px;
        }

        @media(max-width: $md6+px) {
            width: 280px;
            margin-right: 20px
        }
    }
}