@import '../../../styles/var';

.wrap {
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, minmax(300px, 525px));
    justify-content: space-between;
    gap: 50px;

    @media(max-width: 900px) {
        display: flex;
        flex-direction: column;
    }

    @media(max-width: $md6+px) {
        gap: 40px;
    }

    .col {

        b {
            font-weight: 700;
        }
    }
}