@import '../../../styles/var';

.wrap {
    position: relative;

    .cards {
        display: grid;
        grid-template-columns: 1fr 1fr 210px;
        grid-template-rows: repeat(2, 295px);
        gap: 25px;

        @media (max-width: $md4+px) {
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, 1fr);
            gap: 20px;
        }

        @media (max-width: 700px) {
            display: flex;
            flex-direction: column;
        }

        @media (max-width: $md6+px) {
            gap: 15px;
        }

        .card {
            position: relative;
            overflow: hidden;
            border-radius: 30px;
            background-position: bottom center;
            background-repeat: no-repeat;
            background-size: cover;
            padding: 30px;

            @media (max-width: $md4+px) {
                background-image: none !important;
                padding: 30px !important;
                grid-column: initial !important;
                grid-row: initial !important;
            }

            @media (max-width: $md6+px) {
                border-radius: 20px;
            }

            .title,
            .text {
                position: relative;
                z-index: 3;
                color: inherit;
            }

            .title {
                margin-bottom: 15px;
            }

            .text {
                max-width: 380px;
                max-width: 320px;
            }

            &_01 {
                grid-column: 1/1;
                grid-row: 1/3;
                background-image: url('../../../assets/img/CfdPage/Cards/image_01.webp');
                color: #fff;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                padding-bottom: 50px;

                @media (max-width: $md4+px) {
                    background: #D63D45;
                }

                &::before {
                    content: '';
                    position: absolute;
                    z-index: 2;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 340px;
                    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00)0%, rgba(54, 54, 54, 0.90)100%);

                    @media (max-width: $md4+px) {
                        content: none;
                    }
                }
            }

            &_02 {
                grid-column: 2/2;
                grid-row: 1/1;
                background: #B73D2B;
                color: #fff;
            }

            &_03 {
                grid-column: 3/3;
                grid-row: 1/3;
                background-image: url('../../../assets/img/CfdPage/Cards/image_03.webp');
                color: #303030;
                padding: 30px 20px;

                @media (max-width: $md4+px) {
                    background: #EEEFF2;
                }
            }

            &_04 {
                grid-column: 2/2;
                grid-row: 2/2;
                background-image: url('../../../assets/img/CfdPage/Cards/image_04.webp');
                color: #303030;

                @media (max-width: $md4+px) {
                    background: #EEEFF2;
                }
            }
        }
    }
}