@import '../../../styles/var';

.wrap {
    position: relative;

}

.container {
    position: relative;
    z-index: 5;

    .title {
        max-width: 525px;
    }

    .text {
        max-width: 525px;
        margin-top: 30px;

        @media(max-width: $md3+px) {
            max-width: 480px;
        }

        @media(max-width: $md4+px) {
            max-width: 100%;
        }
    }
}

.image {
    position: absolute;
    z-index: 3;
    bottom: 0;
    right: 0;
    width: 742px;

    @media(max-width: $md2+px) {
        width: 650px;
    }

    @media(max-width: $md3+px) {
        width: 500px;
    }

    @media(max-width: $md4+px) {
        max-width: 500px;
        position: relative;
        right: unset;
        bottom: unset;
        margin: 50px 0 0 auto;
        display: flex;
        width: 82%;
    }
}