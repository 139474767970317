@import '../../../styles/var';

.wrap {
    position: relative;
    display: grid;
    grid-template-columns: 480px minmax(300px, 430px);
    justify-content: space-between;
    align-items: center;
    gap: 60px;

    @media (max-width: $md4+px) {
        display: flex;
        flex-direction: column;
        gap: 50px;
    }

    .content {
        display: flex;
        flex-direction: column;
        gap: 30px;

        .list {
            display: flex;
            flex-direction: column;
            gap: 20px;

            .item {
                display: grid;
                grid-template-columns: 27px 1fr;
                gap: 20px;

                @media(max-width: $md6+px) {
                    grid-template-columns: 22px 1fr;
                    gap: 15px;
                }

                .check {
                    width: 100%;
                }

                p {
                    padding-top: 2px;
                }
            }
        }
    }
    
    .image {
        width: 100%;
        max-width: 430px;

        @media (max-width: $md4+px) {
            max-width: 320px;
        }

        @media (max-width: $md6+px) {
            max-width: 200px;
        }
    }
}