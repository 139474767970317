@import '../../../styles/var';

.wrap {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, auto);
    gap: 35px;

    @media(max-width: $md4+px) {
        gap: 15px;
    }

    @media(max-width: $md5+px) {
        display: flex;
        flex-direction: column;
    }

    @media(max-width: $md6+px) {
        gap: 10px;
    }

    .card {
        border-radius: 50px;

        @media(max-width: $md4+px) {
            border-radius: 30px;
        }

        @media(max-width: $md6+px) {
            border-radius: 20px;
            padding: 20px 18px !important;
        }

        .subtitle {
            font-weight: 700;
        }

        &_01 {
            background: #EFECE7;
            padding: 40px 40px 40px 70px;
            display: grid;
            grid-template-columns: 1fr 483px;
            align-items: center;
            gap: 100px;
            grid-column: 1/3;

            @media(max-width: $md3+px) {
                grid-template-columns: repeat(2, 1fr);
                gap: 60px;
                padding: 40px;
            }

            @media(max-width: $md4+px) {
                display: flex;
                flex-direction: column;
                gap: 40px;
                padding: 30px;
            }

            .content {

                .text {
                    margin-top: 30px;
                }
            }

            .image {

                @media(max-width: $md4+px) {
                    width: 100%;
                    max-width: 400px;
                }

                img {
                    width: 100%;
                }
            }
        }

        &_02 {
            background: #EFECE7;

            .subtitle {
                color: $red;
            }
        }

        &_03 {
            background: $red;
            color: #fff;
        }

        &_02,
        &_03 {
            padding: 37px 45px;

            @media(max-width: $md3+px) {
                padding: 35px 40px;
            }

            @media(max-width: $md4+px) {
                padding: 30px;
            }
        }
    }
}