@import '../../../styles/var';

.wrap {
    position: relative;

    .cards {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr 380px;
        gap: 50px 35px;

        @media(max-width: $md3+px) {
            gap: 30px 15px;
        }

        @media(max-width: $md4+px) {
            display: flex;
            flex-direction: column;
            gap: 20px;
        }

        @media(max-width: $md6+px) {
            gap: 10px;
        }

        .card {
            position: relative;
            padding: 40px 25px;
            border-radius: 20px;

            @media(max-width: $md6+px) {
                padding: 25px 18px;
            }

            @media(max-width: $md4+px) {
                background-image: none !important;

                .image {
                    display: none !important;
                }
            }

            .title {
                position: relative;
                z-index: 5;
            }

            .text {
                position: relative;
                z-index: 5;
                margin-top: 35px;

                @media(max-width: $md4+px) {
                    margin-top: 30px;
                }

                @media(max-width: $md6+px) {
                    margin-top: 15px;
                }
            }

            &_01 {
                grid-column-start: 1;
                grid-column-end: 3;
                grid-row-start: 1;
                grid-row-end: 1;
                padding: 0 !important;

                .text {
                    max-width: 525px;

                    @media(max-width: $md6+px) {
                        margin-top: 30px;
                    }
                }

                .btn {
                    margin-top: 35px;

                    @media(max-width: $md4+px) {
                        margin-bottom: 40px;
                        margin-top: 40px;
                    }
                }
            }

            &_02 {
                grid-column-start: 1;
                grid-column-end: 1;
                grid-row-start: 2;
                grid-row-end: 2;
                background: #EFECE7;
            }

            &_03 {
                grid-column-start: 2;
                grid-column-end: 2;
                grid-row-start: 2;
                grid-row-end: 2;
                background: $red;

                .title,
                .text {
                    color: #fff;
                }
            }

            &_02,
            &_03 {
                background-image: url('../../../assets/img/TradingPage/WhyInvest/image_01.webp');
                background-repeat: no-repeat;
                background-position: center center;
                background-size: cover;
            }

            &_04 {
                grid-column-start: 3;
                grid-column-end: 3;
                grid-row-start: 1;
                grid-row-end: 3;
                background: #EFECE7;

                .image {
                    position: absolute;
                    z-index: 2;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    bottom: 0;
                    bottom: 50px;
                }
            }
        }
    }
}