@import '../../../styles/var';

.banner {
    position: relative;
    margin-top: 175px;

    @media(max-width: $md1+px) {
        margin-top: 140px;
    }

    @media(max-width: $md4+px) {
        margin-top: 120px;
    }

    @media(max-width: $md6+px) {
        margin-top: 100px;
    }

    .title, .subtitle, .btn, .list {
        position: relative;
        z-index: 5;
    }

    .title {
        color: #000;
        font-family: $inter;
        font-size: 145px;
        font-weight: 700;
        line-height: 150%;

        @media(max-width: $md1+px) {
            font-size: 130px;
        }

        @media(max-width: $md4+px) {
            font-size: 110px;
        }

        @media(max-width: 600px) {
            font-size: 70px;
        }

        span {
            color: $red;
        }
    }

    .subtitle {
        margin: -35px 0 45px;

        @media(max-width: $md1+px) {
            max-width: 700px;
        }

        @media(max-width: $md3+px) {
            max-width: 100%;
        }

        @media(max-width: $md4+px) {
            margin-top: -30px;
        }

        @media(max-width: 600px) {
            margin-top: -16px;
        }
    }

    .list {
        display: flex;
        gap: 25px;
        margin-top: 50px;

        @media(max-width: $md3+px) {
            flex-direction: column;
            margin-top: 40px;
        }   

        @media(max-width: $md5+px) {
            align-items: center;
            gap: 40px;
            margin-top: 100px;
        }

        .item {
            display: flex;
            flex-direction: column;
            max-width: 200px;
            width: 200px;
            gap: 15px;
            align-items: center;
            text-align: center;

            @media(max-width: $md6+px) {
                max-width: 180px;
            }

            &Icon {
                width: 43px;
            }

            &:nth-child(1),
            &:nth-child(3) {
                padding-top: 100px;

                @media(max-width: $md3+px) {
                    padding-top: 0;
                }
            }
        }
    }

    .image {
        position: absolute;
        z-index: 2;
        right: -210px;
        bottom: -65px;
        width: 755px;

        @media(max-width: $md1+px) {
            width: 650px;
            right: -80px;
        }

        @media(max-width: $md3+px) {
            width: 600px;
            right: -20px;
        }

        @media(max-width: $md4+px) {
            width: 450px;
            bottom: -20px;
        }

        @media(max-width: $md5+px) {
            width: 100%;
            max-width: 450px;
            position: relative;
            display: flex;
            margin: 50px auto 0;
            bottom: unset;
            right: unset;
        }
    }
}