@import '../../../styles/var';

.wrap {
    padding-top: 60px;

    @media(max-width: $md4+px) {
        padding-top: 0;
    }

    .head {
        display: grid;
        grid-template-columns: repeat(2, minmax(300px, 525px));
        justify-content: space-between;
        gap: 50px;

        @media(max-width: 850px) {
            display: flex;
            flex-direction: column;
        }

        @media(max-width: $md6+px) {
            gap: 30px;
        }

        .content {
            
            .title {
                max-width: 525px;
            }

            .btn {
                @media(max-width: 850px) {
                    display: none;
                }
            }
        }

        .image {
            display: flex;
            max-width: 525px;
            margin: 0 auto;
            img {
                width: 100%;
            }
        }
    }

    .cards {
        margin-top: 35px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 35px;

        @media(max-width: $md4+px) {
            gap: 15px;
        }

        @media(max-width: $md5+px) {
            display: flex;
            flex-direction: column;
            margin: 15px auto 0;
            max-width: 525px;
        }
        
        .card {
            background: $red;
            border-radius: 20px;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            height: 127px;
            padding: 10px 20px;

            @media(max-width: $md5+px) {
                height: 90px;
            }

            @media(max-width: $md6+px) {
                height: 86px;
            }

            .text {
                max-width: 250px;
            }
        }
    }
}