@import "../../../styles/var";

.button {
    position: relative;
    display: inline-flex;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s;
    overflow: hidden;

    padding: 16px 55px;
    background: #000;
    border-radius: 60px;

    font-family: $sans;
    font-weight: 600;
    font-size: 17px;
    line-height: 120%;
    color: #fff;

    @media(max-width: $md4+px) {
        font-size: 16px;
    }

    @media (max-width: $md6+px) {
        font-size: 14px;
    }

    &:hover {
        background: rgba($color: #000, $alpha: 0.9);

        span {
            transform: translateX(0);
        }

        &::before {
            right: -30px;
        }
    }

    &:active {
        transform: scale(0.96);
        background: rgba($color: #000, $alpha: 0.7);
        transition: all 0.1s;
    }

    span {
        position: relative;
        width: 100%;
        text-align: center;
        transform: translateX(-23px);
        transition: all .3s;
    }

    &::before {
        content: '';
        position: absolute;
        right: 32px;
        top: 50%;
        transform: translateY(-50%);
        width: 24px;
        height: 24px;
        background-image: url('../../../assets/img/button_arrow.svg');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        transition: all .3s;
        pointer-events: none;
    }

    &.white {
        background: #fff;
        color: #000;

        &::before {
            background-image: url('../../../assets/img/button_arrow_dark.svg');
        }
    }
}