@import '../../../styles/var';

.wrap {
    position: relative;
    display: grid;
    grid-template-columns: minmax(300px, 540px) minmax(300px, 525px);
    justify-content: space-between;
    gap: 50px;

    @media(max-width: $md4+px) {
        display: flex;
        flex-direction: column;
        gap: 40px;
    }

    .content {

        .title {
            max-width: 540px;
        }
    }

    .tabs {
        width: 100%;

        display: flex;
        flex-direction: column;
        gap: 10px;

        .tab {
            background: #551A11;
            color: #fff;
            border-radius: 8px;

            &.active {
                background: $red;

                .tabText {
                    display: flex;
                }

                .tabStatus::before {
                    transform: translateX(-50%) translateY(-50%) rotate(-90deg);
                }
            }

            &Header {
                cursor: pointer;
                padding: 20px;
                display: grid;
                grid-template-columns: 1fr 22px;
                gap: 40px;
                align-items: center;

                @media(max-width: $md6+px) {
                    grid-template-columns: 1fr 16px;
                    padding: 20px 15px;
                }

                .tabTitle {
                    font-weight: 600;
                }

                .tabStatus {
                    position: relative;
                    width: 22px;
                    height: 22px;

                    @media(max-width: $md6+px) {
                        width: 16px;
                        height: 16px;
                    }

                    &::before,
                    &::after {
                        content: '';
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translateX(-50%) translateY(-50%);
                        background: #fff;
                        transition: all .1s;
                    }

                    &::before {
                        width: 2px;
                        height: 100%;
                    }

                    &::after {
                        width: 100%;
                        height: 2px;
                    }
                }
            }

            &Text {
                display: none;
                padding: 0 85px 20px 20px;

                @media(max-width: $md6+px) {
                    padding: 0 15px 20px 15px;
                }
            }
        }
    }
}