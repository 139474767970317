@import '../../../styles/var';

.wrap {
    position: relative;

    .body {
        display: grid;
        grid-template-columns: repeat(2, minmax(300px, 525px));
        justify-content: space-between;
        gap: 60px;

        @media (max-width: $md4+px) {
            display: flex;
            flex-direction: column;
            gap: 35px;
        }

        .content {
            
            .title {
                max-width: 350px;
            }

            .text {
                margin: 30px 0 15px;
            }

            .desc {
                font-weight: 700;
            }
        }

        .image {
            width: 100%;
            max-width: 525px;
            
            @media (max-width: $md4+px) {
                width: 100%;
                max-width: 450px;
                display: flex;
                margin: 0 auto;
            }
        }
    }

    .cards {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(1, 1fr);
        gap: 35px;
        margin-top: 35px;

        @media (max-width: $md4+px) {
            gap: 15px;
        }

        @media (max-width: $md5+px) {
            grid-template-columns: repeat(1, 1fr);
            grid-template-rows: repeat(3, 1fr);
        }

        @media (max-width: $md6+px) {
            gap: 10px;
        }

        .card {
            background: $red;
            border-radius: 20px;
            min-height: 126px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            color: #fff;
            padding: 10px;

            @media (max-width: $md4+px) {
                min-height: 110px;
            }

            @media (max-width: $md5+px) {
                min-height: 90px;
            }

            @media (max-width: $md6+px) {
                min-height: 86px;
            }
        }
    }
}