@import '../../../styles/var';

.wrap {
    
    .title {
        max-width: 400px;

        @media(max-width: $md6+px) {
            max-width: 280px;
        }
    }

    .content {
        display: grid;
        grid-template-columns: repeat(2, minmax(300px, 525px));
        justify-content: space-between;
        gap: 50px;
        margin-top: 30px;

        @media(max-width: $md4+px) {
            display: flex;
            flex-direction: column-reverse;
            align-items: center;
        }

        @media(max-width: $md6+px) {
            gap: 40px;
        }

        .image {
            max-width: 525px;

            @media(max-width: $md6+px) {
                max-width: 280px;
            }

            img {
                width: 100%;
            }
        }

        .list {
            display: flex;
            flex-direction: column;
            gap: 40px;
            width: 100%;

            @media(max-width: $md3+px) {
                gap: 20px;
            }

            .item {
                display: grid;
                grid-template-columns: 41px 1fr;
                gap: 30px;

                @media(max-width: $md6+px) {
                    grid-template-columns: 30px 1fr;
                    gap: 20px;
                }

                .check {
                    width: 100%;
                }
    
                &Content {
    
                    .subtitle {
    
                        b {
                            font-weight: 700;
                        }
                    }
    
                    .text {
                        margin-top: 15px;
                    }
                }
            }
        }
    }
}