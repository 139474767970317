@import '../../../styles/var';

.wrap {
    display: flex;
    justify-content: space-between;
    gap: 50px;
    align-items: center;

    @media(max-width: $md4+px) {
        flex-direction: column;
        align-items: center;
        gap: 40px;
    }

    .content {
        width: 100%;

        .title {
            max-width: 530px;
        }

        .blocks {
            display: flex;
            flex-direction: column;
            gap: 30px;
            margin-top: 50px;

            @media(max-width: $md4+px) {
                margin-top: 30px;
            }

            .block {
                max-width: 525px;

                @media(max-width: $md4+px) {
                    max-width: 100%;
                }
            }
        }
    }

    .image {
        max-width: 346px;
        width: 100%;

        @media(max-width: $md6+px) {
            max-width: 200px;
        }

        img {
            width: 100%;
        }
    }
}