@import '../../../styles/var';

.wrap {
    display: grid;
    grid-template-columns: 1fr auto;
    justify-content: space-between;
    gap: 150px;

    @media(max-width: $md3+px) {
        gap: 100px;
    }   

    @media(max-width: $md5+px) {
        display: flex;
        flex-direction: column;
        gap: 30px;
    }

    .col {
        display: grid;
        grid-template-columns: 380px 1fr;
        gap: 35px;
        
        @media(max-width: $md3+px) {
            grid-template-columns: 280px 1fr;
        }

        @media(max-width: $md4+px) {
            display: block;
        }

        .graph {
            background: $red;
            border-radius: 20px;
            display: flex;
            justify-content: space-between;
            padding: 20px 45px;

            @media(max-width: $md3+px) {
                padding: 20px 20px;
            }

            @media(max-width: $md4+px) {
                display: none;
            }

            .candle {
                position: relative;
                height: 100%;
                width: 16px;
                border-radius: 20px;
                z-index: 3;
                background: #7E2315;
                display: flex;
                align-items: flex-end;

                @media(max-width: $md3+px) {
                    width: 12px;
                }

                &:hover {
                    .candleWhite {
                        height: 100%!important;
                    }
                }
                
                .candleWhite {
                    width: 100%;
                    background: #fff;
                    border-radius: 20px;
                    transition: all .3s;
                }
            }
        }

        .content {

            .title {}

            .text {
                margin-top: 25px;

                @media(max-width: $md6+px) {
                    margin-top: 15px;
                }
            }
        }
    }

    .buttons {
        display: flex;
        flex-direction: column;
        gap: 10px;
        max-width: 270px;

        @media(max-width: $md6+px) {
            max-width: 100%;
            width: 100%;
            gap: 6px;
        }

        .button {
            display: flex;
            align-items: center;
            gap: 20px;
            padding: 0 8px;
            background: transparent;
            transition: all .1s;
            cursor: pointer;
            border-radius: 10px;

            @media(max-width: $md6+px) {
                padding: 10px 16px;
            }

            * {
                transition: all .1s;
            }

            &Num {
                color: #000;
                font-family: $inter;
                font-size: 30px;
                font-weight: 300;
                line-height: 150%;

                @media(max-width: $md4+px) {
                    font-size: 24px;
                }

                @media(max-width: $md6+px) {
                    font-size: 18px;
                }
            }

            &Text {
                color: #000;
            }

            &:hover {
                background: rgba($color: $red, $alpha: 0.2);
            }

            &.active {
                background: $red!important;

                .buttonNum,
                .buttonText {
                    color: #fff;
                }
            }
        }
    }
}