@import '../../../styles/var';

.wrap {
    position: relative;
    margin-top: 175px;

    @media(max-width: $md1+px) {
        margin-top: 160px;
    }

    @media(max-width: $md4+px) {
        margin-top: 140px;
    }

    @media(max-width: $md6+px) {
        margin-top: 120px;
    }

    .banner {
        position: relative;

        .content {
            position: relative;
            z-index: 5;

            .title {}

            .text {
                margin-top: 15px;
                max-width: 525px;

                @media(max-width: $md3+px) {
                    max-width: 450px;
                }

                @media(max-width: $md4+px) {
                    max-width: 100%;
                }
            }
        }

        .image {
            position: absolute;
            z-index: 2;
            bottom: 0;
            right: 0;
            width: 525px;

            @media(max-width: $md3+px) {
                width: 450px;
            }

            @media(max-width: $md4+px) {
                position: relative;
                display: flex;
                margin: 40px auto 0;
                max-width: 450px;
                width: 100%;
            }

            img {
                width: 100%;
            }
        }
    }

    .cards {
        margin-top: 45px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 35px;

        @media(max-width: $md3+px) {
            gap: 15px;
        }

        @media(max-width: $md4+px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media(max-width: $md6+px) {
            display: flex;
            flex-direction: column;
        }

        .card {
            border-radius: 20px;
            background: #B73D2B;
            min-height: 127px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            padding: 10px 20px;
            color: #FFF;

            @media(max-width: $md4+px) {
                min-height: 110px;
                padding: 10px 15px;
            }

            @media(max-width: $md3+px) {
                min-height: 88px;
            }
        }
    }
}