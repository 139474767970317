@import '../../../styles/var';

.wrap {
    position: relative;

    .title {}

    .list {
        display: flex;
        flex-direction: column;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, minmax(300px, 525px));
        justify-content: space-between;
        margin-top: 50px;
        gap: 30px 50px;

        @media(max-width: $md4+px) {
            margin-top: 40px;
            display: flex;
            flex-direction: column;
        }

        @media(max-width: $md6+px) {
            margin-top: 30px;
            gap: 30px;
        }

        .item {
            display: grid;
            grid-template-columns: 41px 1fr;
            gap: 30px;

            @media(max-width: $md6+px) {
                grid-template-columns: 30px 1fr;
                gap: 20px;
            }

            .check {
                width: 100%;
            }

            &Content {

                .subtitle {

                    b {
                        font-weight: 700;
                    }
                }

                .text {
                    margin-top: 15px;
                }
            }
        }
    }
}