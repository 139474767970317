@import '../../../styles/var';

.wrap {
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, minmax(300px, 525px));
    justify-content: space-between;
    gap: 50px;

    @media(max-width: $md4+px) {
        display: flex;
        flex-direction: column;
        gap: 30px;
    }

    .content {

        .title {
            display: flex;
            flex-direction: column;
            max-width: 350px;

            @media(max-width: $md6+px) {
                max-width: 260px;
            }

            span {
                font-family: $inter;
                font-size: 75px;
                font-weight: 700;
                line-height: 130%;

                @media(max-width: $md4+px) {
                    font-size: 60px;
                }

                @media(max-width: $md6+px) {
                    font-size: 40px;
                }
            }
        }

        .btn {
            margin-top: 30px;

            @media(max-width: $md4+px) {
                display: none;
            }
        }
    }

    .text {
        padding-top: 40px;

        @media(max-width: $md4+px) {
            padding-top: 0;
        }
    }
}