@import '../../../styles/var';

.wrap {

    .title {
        text-align: center;
    }

    .subtitle {
        text-align: center;
        margin: 10px 0 65px;

        @media(max-width: $md4+px) {
            margin-bottom: 50px;
        }

        @media(max-width: $md6+px) {
            margin-bottom: 30px;
        }
    }

    .cards {
        display: flex;
        justify-content: space-between;
        gap: 60px;

        @media(max-width: $md3+px) {
            flex-direction: column;
            align-items: center;
            gap: 40px;
        }

        @media(max-width: $md6+px) {
            gap: 30px;
        }

        .card {
            max-width: 320px;

            @media(max-width: $md3+px) {
                width: 500px;
                max-width: 100%;
            }

            &:hover {
                .cardNum {
                    transform: translateY(-10px);
                }
            }

            &Num {
                width: 44px;
                height: 44px;
                border-radius: 10px;
                background: $red;
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                transition: all .3s;

                @media(max-width: $md6+px) {
                    width: 34px;
                    height: 34px;
                }
            }

            &Title {
                margin: 15px 0;

                @media(max-width: $md6+px) {
                    margin: 10px 0;
                }
            }

            &Text {
                
            }
        }
    }
}