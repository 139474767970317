@import '../../../styles/var';

.wrap {
    position: relative;
    display: flex;
    justify-content: space-between;
    gap: 50px;

    @media(max-width: $md4+px) {
        flex-direction: column;
        align-items: center;
        gap: 40px;
    }

    .content {
        position: relative;
        z-index: 5;
        width: 100%;

        @media(max-width: $md3+px) {
            width: 50%;
        }

        @media(max-width: $md4+px) {
            width: 100%;
        }

        .title {
            max-width: 530px;
        }

        .text {
            max-width: 525px;
            margin-top: 30px;

            @media(max-width: $md4+px) {
                max-width: 100%;
            }
        }
    }

    .image {
        position: absolute;
        z-index: 2;
        max-width: 730px;
        width: 100%;
        top: 0;
        right: -150px;

        @media(max-width: $md3+px) {
            max-width: 600px;
            top: 25px;
        }   

        @media(max-width: $md4+px) {
            position: relative;
            right: unset;
            top: unset;
            max-width: 600px;
        }

        img {
            width: 100%;
        }
    }
}