@import '../../../styles/var';

.wrap {
    position: relative;
    display: grid;
    grid-template-columns: auto 594px;
    justify-content: space-between;
    align-items: flex-end;
    gap: 60px;

    @media (max-width: $md3+px) {
        grid-template-columns: auto 400px;
    }

    @media (max-width: $md4+px) {
        display: flex;
        flex-direction: column;
        align-items: unset;
        gap: 50px;
    }

    .content {

        .title {
            max-width: 530px;
        }
        
        .list {
            display: flex;
            flex-direction: column;
            gap: 40px;
            margin: 30px 0;

            @media(max-width: $md3+px) {
                gap: 20px;
            }

            .item {
                display: grid;
                grid-template-columns: 27px 1fr;
                gap: 20px;

                @media(max-width: $md6+px) {
                    grid-template-columns: 22px 1fr;
                    gap: 15px;
                }

                .check {
                    width: 100%;
                }
    
                &Content {
                    padding-top: 3px;
                    .subtitle {
    
                        b {
                            font-weight: 700;
                        }
                    }
    
                    .text {
                        margin-top: 15px;
                        max-width: 340px;

                        @media (max-width: $md4+px) {
                            max-width: 100%;
                        }
                    }
                }
            }
        }
    }

    .image {
        @media (max-width: $md4+px) {
            display: flex;
            width: 100%;
            max-width: 450px;
            margin: 0 auto;
        }
        img {
            width: 100%;
        }
    }
}