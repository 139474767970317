@import '../../../styles/var';

.wrap {
    position: relative;

    .content {
        position: relative;
        z-index: 5;

        .title {}

        .text {
            margin-top: 30px;
            max-width: 525px;

            @media (max-width: $md4+px) {
                max-width: 100%;
            }
        }
    }

    .image {
        position: absolute;
        z-index: 2;
        right: 0;
        bottom: 0;
        width: 567px;

        @media (max-width: $md3+px) {
            width: 400px;
        }

        @media (max-width: $md4+px) {
            position: relative;
            right: unset;
            bottom: unset;
            display: flex;
            margin: 40px auto 0;
            max-width: 500px;
            width: 100%;
        }
    }
}