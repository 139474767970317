@import '../../../styles/var';

.wrap {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 60px;
    align-items: center;

    @media (max-width: $md4+px) {
        display: flex;
        flex-direction: column;
        align-items: unset;
    }

    @media (max-width: $md6+px) {
        gap: 40px;
    }
   
    .head {
        .title {}

        .subtitle {
            margin: 5px 0 30px;
            max-width: 420px;

            @media (max-width: $md6+px) {
                max-width: 300px;
            }
        }

        .btn {}
    }

    .list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px 40px;

        @media(max-width: $md3+px) {
            gap: 20px;
        }

        @media (max-width: $md6+px) {
            grid-template-columns: repeat(1, 1fr);
        }

        .item {
            display: grid;
            grid-template-columns: 41px 1fr;
            gap: 30px;
            align-items: center;

            @media(max-width: $md6+px) {
                grid-template-columns: 30px 1fr;
                gap: 20px;
            }

            .check {
                width: 100%;
            }

            .text {
                font-weight: 700;
            }
        }
    }
}