@import '../../../styles/var';

.wrap {
    position: relative;

    .title {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        display: flex;
        flex-direction: column;
        gap: 10px;
        text-align: center;
        max-width: 380px;

        @media(max-width: $md3+px) {
            position: relative;
            top: unset;
            left: unset;
            transform: none;
            text-align: left;
            max-width: unset;
            gap: 5px;
        }

        span {
            color: #000;
            font-family: $inter;
            font-size: 58px;
            font-weight: 700;
            line-height: 150%;
        
            @media(max-width: $md4+px) {
                font-size: 34px;
            }
        
            @media(max-width: $md6+px) {
                font-size: 28px;
            }
        }
    }

    .cards {
        display: grid;
        grid-template-columns: repeat(2, 383px);
        grid-template-rows: repeat(2, 1fr);
        justify-content: space-between;
        gap: 50px;

        @media(max-width: $md3+px) {
            gap: 15px;
            grid-template-columns: repeat(2, 1fr);
            margin-top: 40px;
        }

        @media(max-width: 650px) {
            grid-template-columns: repeat(1, 1fr);
        }

        @media(max-width: $md6+px) {
            margin-top: 30px;
            gap: 10px;
        }

        .card {
            background: $red;
            border-radius: 20px;
            color: #fff;
            text-align: center;
            padding: 24px;

            @media(max-width: $md6+px) {
                padding: 20px;
                gap: 5px;
            }

            b {
                font-weight: 600;
            }
        }
    }
}