@import './var';

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

.font-90 {
    color: #000;
    font-family: $inter;
    font-size: 90px;
    font-weight: 700;
    line-height: 130%;

    @media(max-width: $md3+px) {
        font-size: 65px;
    }

    @media(max-width: $md4+px) {
        font-size: 45px;
    }

    @media(max-width: $md6+px) {
        font-size: 36px;
    }
}

.font-58 {
    color: #000;
    font-family: $inter;
    font-size: 58px;
    font-weight: 700;
    line-height: 150%;

    @media(max-width: $md4+px) {
        font-size: 34px;
    }

    @media(max-width: $md6+px) {
        font-size: 28px;
    }

    span {
        color: $red;
    }
}

.font-35 {
    color: #000;
    font-family: $inter;
    font-size: 35px;
    font-weight: 700;
    line-height: 150%;

    @media(max-width: $md4+px) {
        font-size: 24px;
    }

    @media(max-width: $md6+px) {
        font-size: 18px;
    }
}

.font-30 {
    color: #000;
    font-family: $inter;
    font-size: 30px;
    font-weight: 700;
    line-height: 150%;

    @media(max-width: $md4+px) {
        font-size: 24px;
    }

    @media(max-width: $md6+px) {
        font-size: 18px;
    }

    span {
        color: $red;
    }
}

.font-25 {
    color: #000;
    font-family: $inter;
    font-size: 25px;
    font-weight: 700;
    line-height: 150%;

    @media(max-width: $md4+px) {
        font-size: 20px;
    }

    @media(max-width: $md6+px) {
        font-size: 18px;
    }

    span {
        color: $red;
    }
}

.font-20 {
    color: #000;
    font-family: $inter;
    font-size: 20px;
    font-weight: 600;
    line-height: 140%;

    @media(max-width: $md4+px) {
        font-size: 18px;
    }

    @media(max-width: $md6+px) {
        font-size: 16px;
    }
}

.font-18 {
    color: #000;
    font-family: $sans;
    font-size: 18px;
    font-weight: 400;
    line-height: 150%;

    @media(max-width: $md4+px) {
        font-size: 16px;
    }

    @media(max-width: $md6+px) {
        font-size: 14px;
    }
}

.font-17 {
    font-family: $sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;

    @media(max-width: $md4+px) {
        font-size: 16px;
    }

    @media(max-width: $md6+px) {
        font-size: 14px;
    }
}