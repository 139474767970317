@import '../../../styles/var';

.wrap {
    display: grid;
    grid-template-columns: repeat(2, minmax(300px, 525px));
    gap: 50px;
    justify-content: space-between;

    @media(max-width: 900px) {
        display: flex;
        flex-direction: column;
        gap: 40px;
    }

    .col {
        b {
            font-weight: 700;
        }

        .image {
            max-width: 525px;
            width: 100%;
            
            @media(max-width: 900px) {
                display: flex;
                margin: 0 auto;
                max-width: 450px;
            }

            @media(max-width: $md6+px) {
                max-width: 250px;
            }
        }
    }
}

.ttl {
    margin-top: 15px;
}

.gap50 {
    display: flex;
    flex-direction: column;
    gap: 50px;

    @media(max-width: 900px) {
        gap: 40px;
    }
}