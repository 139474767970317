@import '../../../styles/var';

.wrap {
    position: relative;
    margin-top: 200px;

    @media(max-width: $md1+px) {
        margin-top: 160px;
    }

    @media(max-width: $md4+px) {
        margin-top: 140px;
    }

    @media(max-width: $md6+px) {
        margin-top: 120px;
    }

    .content {
        position: relative;
        z-index: 5;

        .title {

        }

        .text {
            max-width: 525px;
            margin-top: 30px;

            @media (max-width: $md4+px) {
                max-width: 100%;
            }
        }
    }

    .image {
        position: absolute;
        z-index: 2;
        right: 40px;
        top: -20px;
        width: 434px;

        @media (max-width: $md3+px) {
            width: 360px;
            right: 0;
            top: 10px;
        }

        @media (max-width: $md4+px) {
            position: relative;
            right: unset;
            top: unset;
            display: flex;
            margin: 40px auto 0;
        }

        @media (max-width: $md6+px) {
            width: 260px;
        }
    }
}