@import '../../../styles/var';

.wrap {
    position: relative;

    .title {}

    .cols {
        margin-top: 30px;
        display: grid;
        grid-template-columns: repeat(2, minmax(300px, 525px));
        justify-content: space-between;
        gap: 60px;

        @media (max-width: $md4+px) {
            grid-template-columns: repeat(1, 1fr);
            gap: 30px;
        }

        .col {
            display: flex;
            flex-direction: column;
            gap: 30px;

            .text {}

            .list {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 25px 60px;

                @media(max-width: $md3+px) {
                    gap: 20px;
                }

                .item {
                    display: grid;
                    grid-template-columns: 27px 1fr;
                    gap: 20px;
                    font-weight: 700;

                    @media(max-width: $md6+px) {
                        grid-template-columns: 22px 1fr;
                        gap: 15px;
                    }

                    .check {
                        width: 100%;
                    }
                }
            }

            .block {

                .subtitle {
                    font-weight: 700;
                    margin-bottom: 15px;
                }
            }
        }
    }
}