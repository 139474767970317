@import '../../../styles/var';

.wrap {
    background: #D9D9D9;
}

.container {
    position: relative;
    height: 100vh;
    min-height: 800px;
    max-height: 1000px;
    display: flex;
    align-items: center;

    @media(max-width: $md1+px) {
        min-height: 650px;
        max-height: 850px;
    }
    
    @media(max-width: $md3+px) {
        min-height: unset;
        max-height: unset;
        height: auto;
        padding: 200px 0;
    }

    @media(max-width: $md4+px) {
        padding: 140px 0 0;
        display: block;
    }

    @media(max-width: $md6+px) {
        padding-top: 120px;
    }

    .content {
        position: relative;
        z-index: 5;

        .title {

        }

        .subtitle {
            margin: 5px 0 30px;
            max-width: 600px;
        }

        .text {
            max-width: 525px;
        }
    }

    .image {
        position: absolute;
        z-index: 2;
        bottom: 0;
        right: -600px;
        width: 1467px;

        @media(max-width: $md1+px) {
            width: 1100px;
            right: -350px;
        }

        @media(max-width: $md3+px) {
            width: 800px;
            right: -250px;
        }   

        @media(max-width: $md4+px) {
            position: relative;
            bottom: unset;
            display: flex;
            margin-left: auto;
            right: -200px;
            width: 700px;
            margin-top: 50px;
        }

        @media(max-width: 850px) {
            right: -100px;
        }

        @media(max-width: $md5+px) {
            right: -40px;
        }

        @media(max-width: 600px) {
            width: 550px;
        }

        @media(max-width: $md6+px) {
            right: -30px;
            width: 400px;
            margin-top: 30px;
        }
    }
}