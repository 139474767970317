@import '../../../styles/var';

.wrap {
    display: flex;
    justify-content: space-between;
    gap: 50px;
    align-items: center;

    @media(max-width: $md4+px) {
        flex-direction: column;
        align-items: center;
        gap: 40px;
    }

    .content {
        width: 100%;

        .title {
            max-width: 530px;
        }

        .text {
            max-width: 525px;
            margin-top: 30px;

            @media(max-width: $md4+px) {
                max-width: 100%;
            }
        }
    }

    .image {
        max-width: 382px;
        width: 100%;
        margin-right: 100px;

        @media(max-width: $md3+px) {
            margin-right: 0;
        }

        @media(max-width: $md4+px) {
            max-width: 350px;
        }

        @media(max-width: $md6+px) {
            max-width: 260px;
        }

        img {
            width: 100%;
        }
    }
}