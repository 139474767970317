@import '../../../styles/var';

.wrap {

    .cards {

        .cardWrap {
            border-bottom: 2px solid #000;
            transition: background .3s;
            background: transparent;

            &:hover {
                background: rgba($color: $red, $alpha: 0.2);
            }

            .card {
                padding: 20px 0;
                min-height: 181px;
                display: flex;
                align-items: center;
                gap: 60px;

                @media(max-width: $md4+px) {
                    gap: 40px;
                    min-height: 150px;
                }

                @media(max-width: $md5+px) {
                    display: grid;
                    grid-template-columns: 1;
                    grid-template-rows: 3;
                    gap: 25px;
                    min-height: unset;
                }

                .title {

                    @media(max-width: $md5+px) {
                        grid-row-start: 1;
                        grid-row-end: 1;
                    }
                }

                .text {
                    @media(max-width: $md5+px) {
                        grid-row-start: 2;
                        grid-row-end: 2;
                    }
                }

                .image {
                    height: 136px;

                    @media(max-width: $md3+px) {
                        height: 110px;
                    }

                    @media(max-width: $md4+px) {
                        height: 90px;
                    }

                    @media(max-width: $md5+px) {
                        grid-row-start: 3;
                        grid-row-end: 3;
                        width: 100%;
                        height: 120px;
                        object-fit: cover;
                        object-position: center center;
                    }
                }
            }
        }

        .headWrap {
            background: $red!important;

            .head {
                gap: 150px;

                @media(max-width: $md3+px) {
                    gap: 110px;
                }

                @media(max-width: $md4+px) {
                    gap: 80px;
                }

                @media(max-width: $md5+px) {
                    grid-template-rows: 2;
                    gaP: 25px;
                }

                .title {
                    color: #FFF;
                    font-family: $inter;
                    font-size: 58px;
                    font-weight: 700;
                    line-height: 150%;
                    display: flex;
                    flex-direction: column;

                    @media(max-width: $md4+px) {
                        font-size: 45px;
                    }

                    span {
                        text-align: right;
                        font-size: 30px;
                        line-height: 150%;
                        margin-top: -20px;
                        padding-right: 40px;

                        @media(max-width: $md4+px) {
                            font-size: 24px;
                            margin-top: -12px;
                            padding-right: 30px;
                        }

                        @media(max-width: $md5+px) {
                            text-align: left;
                            padding-right: 0;
                            padding-left: 40px;
                        }

                        @media(max-width: $md6+px) {
                            font-size: 20px;
                        }
                    }
                }

                .text {
                    color: #fff;
                }
            }
        }
    }
}