@import '../../../styles/var';

.wrap {
    position: relative;
    background: $red;
    border-radius: 50px;
    padding: 40px 70px 50px;
    
    @media(max-width: $md3+px) {
        padding: 40px 50px 50px;
    }

    @media(max-width: $md4+px) {
        padding: 40px 50px 0;
    }

    @media(max-width: $md5+px) {
        border-radius: 40px;
        padding: 40px 30px 0;
    }

    @media(max-width: $md6+px) {
        border-radius: 20px;
        padding: 30px 18px 0;
    }

    .content {
        position: relative;
        z-index: 5;
        max-width: 525px;

        @media(max-width: $md4+px) {
            max-width: 100%;
        }

        .title {
            color: #fff;
            font-family: $inter;
            font-size: 75px;
            font-weight: 700;
            line-height: 130%;

            @media(max-width: $md4+px) {
                font-size: 60px;
            }

            @media(max-width: $md6+px) {
                font-size: 40px;
            }
        }

        .subtitle {
            color: #fff;
            margin: 0 0 15px;
        }

        .text {
            color: #fff;

            @media(max-width: $md3+px) {
                max-width: 430px;
            }

            @media(max-width: $md4+px) {
                max-width: 100%;
            }
        }
    }

    .image {
        position: absolute;
        z-index: 2;
        right: 0;
        bottom: 0;
        width: 693px;

        @media(max-width: $md3+px) {
            width: 600px;
            right: 0;
        }

        @media(max-width: $md4+px) {
            position: relative;
            right: unset;
            bottom: unset;
            display: flex;
            margin: 40px -50px 0 auto;
            max-width: 500px;
            width: 100%;
        }

        @media(max-width: $md5+px) {
            margin-right: -30px;
        }

        @media(max-width: $md6+px) {
            margin-right: -18px;
        }
    }
}