@import '../../../styles/var';

.wrap {
    position: relative;

    .title {

    }

    .content {
        margin-top: 30px;
        display: grid;
        grid-template-columns: repeat(2, minmax(300px, 525px));
        justify-content: space-between;
        gap: 60px;

        @media (max-width: $md4+px) {
            display: flex;
            flex-direction: column;
            gap: 50px;
        }

        .list {
            display: flex;
            flex-direction: column;
            gap: 20px;
            margin-top: 30px;

            .item {
                display: grid;
                grid-template-columns: 27px 1fr;
                align-items: center;
                gap: 20px;

                @media(max-width: $md6+px) {
                    grid-template-columns: 22px 1fr;
                    gap: 15px;
                }

                .check {
                    width: 100%;
                }
            }
        }
    }
}