@import '../../../styles/var';

.wrap {
    position: relative;
    display: grid;
    grid-template-columns: 280px 1fr;
    gap: 35px;

    @media (max-width: $md4+px) {
        grid-template-columns: repeat(1, 1fr);
        gap: 40px;
    }

    .buttons {
        display: flex;
        flex-direction: column;
        gap: 15px;

        @media (max-width: $md4+px) {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
        }

        @media (max-width: $md5+px) {
            grid-template-columns: repeat(2, 1fr);
            gap: 10px;
        }

        .button {
            border-radius: 25px;
            background: #E4E4E4;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            color: #303030;
            cursor: pointer;
            transition: all .1s;
            min-height: 43px;
            padding: 5px 10px;
            font-weight: 500;

            @media (max-width: $md5+px) {
                &:nth-child(5) {
                    grid-column: 1/3;
                    grid-row: 3/3;
                }
            }

            &:hover {
                background: #d8d8d8;
            }

            &.active {
                color: #FFF;
                background: #B73D2B;
            }
        }
    }

    .content {

        .gap30 {
            display: flex;
            flex-direction: column;
            gap: 30px;
        }

        .gap30_15 {
            display: flex;
            flex-direction: column;
            gap: 30px;

            @media (max-width: $md6+px) {
                gap: 15px;
            }
        }

        .gap15 {
            display: flex;
            flex-direction: column;
            gap: 15px;
        }

        .mt50 {
            margin-top: 50px;
        }

        .bold {
            font-weight: 700;
        }

        .title {
            margin-bottom: 30px;
        }

        .block {
            display: flex;
            flex-direction: column;
            gap: 15px;
        }

        .list {
            display: flex;
            flex-direction: column;
            gap: 20px;
            margin-top: 30px;

            @media (max-width: $md6+px) {
                gap: 15px;
            }

            .item {
                display: grid;
                grid-template-columns: 27px 1fr;
                gap: 20px;
                font-weight: 400;
                align-items: center;

                @media(max-width: $md6+px) {
                    grid-template-columns: 22px 1fr;
                    gap: 15px;
                }

                .check {
                    width: 100%;
                }
            }
        }

        .listGrid {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 30px 40px;
            margin-top: 30px;

            @media (max-width: $md5+px) {
                grid-template-columns: repeat(1, 1fr);
                gap: 20px;
            }

            .item {
                display: grid;
                grid-template-columns: 27px 1fr;
                gap: 20px;
                font-weight: 400;
                align-items: flex-start;

                @media(max-width: $md6+px) {
                    grid-template-columns: 22px 1fr;
                    gap: 15px;
                }

                .check {
                    width: 100%;
                }

                p {
                    max-width: 340px;
                }
            }
        }
        
        .list2 {
            display: flex;
            flex-direction: column;
            gap: 40px;
            margin: 30px 0;

            @media(max-width: $md3+px) {
                gap: 20px;
            }

            .item {
                display: grid;
                grid-template-columns: 27px 1fr;
                gap: 30px;

                @media(max-width: $md6+px) {
                    grid-template-columns: 22px 1fr;
                    gap: 15px;
                }

                .check {
                    width: 100%;
                }
    
                &Content {
                    .subtitle {
    
                        b {
                            font-weight: 700;
                        }
                    }
    
                    .text {
                        margin-top: 15px;
                        max-width: 450px;

                        @media (max-width: $md4+px) {
                            max-width: 100%;
                        }
                    }
                }
            }
        }

        .head {
            display: grid;
            grid-template-columns: 382px 1fr;
            gap: 35px;

            @media (max-width: $md3+px) {
                grid-template-columns: 250px 1fr;
            }

            @media (max-width: $md5+px) {
                display: flex;
                flex-direction: column-reverse;
                gap: 40px;
            }

            .image {
                @media (max-width: $md5+px) {
                    display: flex;
                    margin: 0 auto;
                    width: 100%;
                    max-width: 300px;
                }

                img {
                    width: 100%;
                }
            }
        }

        .tab_01 {

            .bigList {
                display: flex;
                flex-direction: column;
                gap: 25px;
                width: 100%;

                @media(max-width: $md3+px) {
                    gap: 20px;
                }

                .item {
                    display: grid;
                    grid-template-columns: 41px 1fr;
                    gap: 30px;

                    @media(max-width: $md6+px) {
                        grid-template-columns: 30px 1fr;
                        gap: 20px;
                    }

                    .check {
                        width: 100%;
                    }

                    &Content {

                        .subtitle {

                            b {
                                font-weight: 700;
                            }
                        }

                        .text {
                            margin-top: 15px;
                        }
                    }
                }
            }
        }
    }
}